import React, {useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './smallProjectPage.css'
import PodcastProjectOne from './collection/podcastPageOne/podcastProjectOne';
import SaveEarth from './collection/saveEarth/saveEarth';

const ProjectFullScreen = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const theme = props.theme;
    const componentId = useParams().componentId;
    const navigate = useNavigate();


    const componentCloseHandler = () => { 
        navigate(`/components/${componentId}/`, {replace: true})
    }

    return ( 
        <React.Fragment>
        <div className={`app ${theme}`} style={{borderBottom: '1px solid var(--color__dark-gray-fix)'}} >
            <div>
                <div style={{height: '10vh'}}></div>
                <button className="closeComponentBtn" style={{marginBottom: 10}} onClick={componentCloseHandler}>Back</button>
            </div>
            
        </div>
        {componentId === 'c1001' ? <PodcastProjectOne /> :
            componentId === 'c1002' ? <SaveEarth /> : null}

        </React.Fragment>
     );
}
 
export default ProjectFullScreen;