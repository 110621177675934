import React from 'react';

import track from '../assets/Track-n1.mp3'
import './musicPlayer.css';

const MusicPlayer = (props) => {
    return ( 
        <div className="musicPlayer">
            <div className="music">
                <h6 style={{color: 'black'}}>Jon Doe</h6>
                <h2 style={{color: 'var(--color__orange)', width: '100%'}}>755 days of solitude.</h2>
                <div className="wave">
                    <audio controls preload>
                        <source src={track} type="audio/mpeg" />
                    </audio>
                </div>
            </div>
        </div>
     );
}
 
export default MusicPlayer;