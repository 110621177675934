import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './smallProjectPage.css'
import '../portfolioPage/portfolioPage.css'
import PodcastProjectOne from './collection/podcastPageOne/podcastProjectOne';
import Projectc1001Mobile from '../assets/projects/c1001-mobile.jpg'
import Projectc1001Desktop from '../assets/projects/c1001-desktop.jpg'
import SaveEarth from './collection/saveEarth/saveEarth';
import Projectc1002Mobile from '../assets/projects/c1002-mobile.png'
import Projectc1002Desktop from '../assets/projects/c1002-desktop.png'




const SmallProjectPage = (props) => {


    useEffect(() => {
        window.scrollTo(0,0);
    }, [])


    const navigate = useNavigate();

    const projectId = useParams().componentId;
    console.log(projectId)


    const [view, setView] = useState('desktop')
    const [iframeWidth, setIframeWidth] = useState('100%')
    const [iframeHeight, setIframeHeight] = useState('fit-content')

    const desktopViewHandler = () => {
        setView('desktop')
        setIframeWidth('100%')
        setIframeHeight('fit-content')
    }
    const mobileViewHandler = () => {
        setView('mobile')
        setIframeWidth(400)
        setIframeHeight(800)
    }
    const fullViewHandler = () => { 
        setView('full')
        setIframeWidth('100%')
        setIframeHeight('fit-content')

        navigate(`/components/${projectId}/full-screen`, {replace: true})
    }


    const [x, setX] = useState()
    const [y, setY] = useState()
    const glowShown = "block"
    useEffect(
        () => {
        const update = (e) => {
            setX(e.x)
            setY(e.y)
        }
        window.addEventListener('mousemove', update)
        window.addEventListener('touchmove', update)
        return () => {
            window.removeEventListener('mousemove', update)
            window.removeEventListener('touchmove', update)
        }
        },
        [setX, setY]
    )

    const y2 = y + Math.random(9000)*100;
    const x2 = x + Math.random(9000)*100;

    const y3 = y + Math.random(3000)*20;
    const x3 = x + Math.random(10000)*200;

    const theme = props.theme;

    

    return ( 
        <div className={`app ${theme}`} style={{width: '100%', minHeight: '100vh'}}>
            <div style={{height: '10rem'}}></div>
            <div className="mouseEffect" style={{top: y, left: x, display: glowShown}} />
            <div className="mouseEffect2" style={{top: y2, left: x2,  display: glowShown}} />
            <div className="mouseEffect2" style={{top: y3, left: x3,  display: glowShown, width: 10, height: 10}} />

            <div className="componentDesc" style={{maxWidth: 1200}}>
            {projectId === "c1001" && <h1>Podcast Website</h1>}
            {projectId === "c1001" && <p>Tech Features and Languages used:Reactjs, HTML, CSS, Firebase<br /><br />This component includes only main page.</p>}


            {projectId === "c1002" && <h1>Webpage, inspired by Notion.</h1>}
            {projectId === "c1002" && <p style={{lineHeight: '1.3rem'}}>This page is designed by Notion, it can be part of a big task management project as a homepage for each member. <br /><br /> In this project I used Reactjs, css and HTML. Also I got help fetching data with Rest APIs.<br /><br />Please keep in mind thet Weather data is not accurate because there is limited capacity for daily clicks and free plan can be max'ed out, but WeatherStack API is implemented in this project and it can be activated on request.</p>}
            
            </div>
            <div className="viewOptions">
                <ul>
                    <li className={view === "desktop" ? "option activeOption" : "option"} onClick={desktopViewHandler}>Desktop View</li>
                    <li className={view === "mobile" ? "option activeOption" : "option"} onClick={mobileViewHandler}>Mobile View</li>
                    <li className={view === "full" ? "option activeOption" : "option"} onClick={fullViewHandler}>Full Screen</li>
                </ul>
            </div>

            <div className="AppIFrame" style={{width: iframeWidth, height: iframeHeight}}>
                {projectId === "c1001" && view === "desktop"  && <img style={{width: '100%'}} src={Projectc1001Desktop} alt="c1001" />}
                {projectId === "c1001" && view === "full"  && <PodcastProjectOne view={view} />}
                {projectId === "c1001" && view === "mobile"  && <img style={{width: '100%'}} src={Projectc1001Mobile} alt="c1001" />}

                {projectId === "c1002" && view === "desktop"  && <img style={{width: '100%'}} src={Projectc1002Desktop} alt="c1001" />}
                {projectId === "c1002" && view === "full"  && <SaveEarth view={view} />}
                {projectId === "c1002" && view === "mobile"  && <img style={{width: '100%'}} src={Projectc1002Mobile} alt="c1001" />}
            </div>
            
        </div>
     );
}
 
export default SmallProjectPage;