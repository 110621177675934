import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../elements/contactForm/contactForm';
import './portfolioPage.css';


import performanceImg from '../assets/icons/megaphone.png';
import browserImg from '../assets/icons/web-development.png';
import startup from '../assets/icons/shuttle.png';
import shoppingImg from '../assets/icons/online-shopping.png';
import myPic from '../assets/images/IMG_5600.JPG';

import TwitterWhite from '../assets/icons/twitter---filled(72x72)@1x (2).png';
import TwitterBlack from '../assets/icons/twitter---filled(72x72)@1x (3).png';
import linkedInWhite from '../assets/icons/linkedin---filled(72x72)@1x (3).png';
import linkedInBlack from '../assets/icons/linkedin---filled(72x72)@1x (2).png';
import gmailBlack from '../assets/icons/gmail---filled(72x72)@1x.png';
import gmailWhite from '../assets/icons/gmail(72x72)@1x.png';

import Projectc1001Desktop from '../assets/projects/c1001-desktop.jpg';
import Projectc1002Desktop from '../assets/projects/c1002-desktop.png';




const PortfolioPage = (props) => {

  const theme = props.theme;

  const [x, setX] = useState()
  const [y, setY] = useState()
  const [ glowShown, setGlowShown ] = useState('block')
  useEffect(
    () => {
      const update = (e) => {
        setX(e.x)
        setY(e.y)
      }
      window.addEventListener('mousemove', update)
      window.addEventListener('touchmove', update)
      return () => {
        window.removeEventListener('mousemove', update)
        window.removeEventListener('touchmove', update)
      }
    },
    [setX, setY]
  )

    const y2 = y + Math.random(9000)*100;
    const x2 = x + Math.random(9000)*100;

    const y3 = y + Math.random(3000)*20;
    const x3 = x + Math.random(10000)*200;


  const myWorks = useRef()
  const contactMe = useRef()

  const goToAboutMeHandler = () => { 
    myWorks.current.scrollIntoView({ behavior: 'smooth', block: 'start',  })
  }
  const goToContactMeHandler = () => { 
    contactMe.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const openComponentHandler = () => { 
    // <Link to="/components/c1001"></Link>
  }


  return (
    <div onMouseEnter={() => setGlowShown("block")} onMouseLeave={() => setGlowShown('none')} className={`app ${theme}`}>

        
      <div className="mouseEffect" style={{top: y, left: x, display: glowShown}} />
      <div className="mouseEffect2" style={{top: y2, left: x2,  display: glowShown}} />
      <div className="mouseEffect2" style={{top: y3, left: x3,  display: glowShown, width: 10, height: 10}} />


    <container className={`app ${theme}`} >
      <section className='MainPage'>
      <div className="mainBanner">
        <div className="mainBannerContent">
          <div>
            <p>-- Hello</p>
            <h1>I'm <span className="orange">Jenna</span> Javadinejad</h1>
            <p>I'm a MERN-stack developer</p><br />
            <p className="desktopExtraContent">Im React developer and e-commerce website creator from Toronto, Canada 🇨🇦. For more information about me and my works, please scroll down or click buttons below ;)</p>
            <p className="desktopExtraContent"><br /><br />But wait, there's more! Regardless of your background, whether you identify as Black, LGBTQ+, Muslim, Atheist, or anything else, know that here, every life matters. If you're a good person, I truly admire you!</p>
            <button onClick={goToAboutMeHandler}>My Works</button>
            <button onClick={goToContactMeHandler}>Contact me</button>
          </div>
            <div>
              <img alt="me" src={myPic} />
            </div>
        </div>
        <div className="mainBannerSocialMedia">
          <ul>
            <li><a href="https://www.linkedin.com/in/faraneh-javadinejad/" target="_blank" rel="noreferrer" alt="linkedin"><img src={theme === "dark" ? linkedInWhite : linkedInBlack} alt="twitter" /></a></li>
            <li><a href="https://twitter.com/ImJennaJay" target="_blank" rel="noreferrer" alt="Twitter"><img src={theme === "dark" ? TwitterWhite : TwitterBlack} alt="twitter" /></a></li>
            <li><a href="mailto:faraneh.javadinejad@gmail.com" target="_blank" rel="noreferrer" alt="linkedin"><img src={theme === "dark" ? gmailWhite : gmailBlack} alt="twitter" /></a></li>
          </ul>
        </div>
      </div>

      </section>
      
      <section className="aboutMe">
        <div className="spaceForMobile" />
       
        <div className="aboutMeTitle">
          <div className="titleLine"></div>
            <h2 style={{margin: 'auto'}}>About Me:</h2><br />
          <div className="titleLine"></div>
        </div>

        <div className="aboutMeTopRight" style={{textAlign: 'center'}}>
            <h3>What I have done in a glance :)</h3><br />
            <ul>
              <li><span style={{display: 'none'}}><a href="https://www.flaticon.com/free-icons/ecommerce" title="ecommerce icons">Ecommerce icons created by bqlqn - Flaticon</a></span><img src={shoppingImg} alt="webDev"></img>5+ years e-commerce</li>
              <li><span style={{display: 'none'}}><a href="https://www.flaticon.com/free-icons/development" title="development icons">Development icons created by Freepik - Flaticon</a></span><img src={browserImg} alt="webDev"></img>5+ years web developer</li>
              <li><span style={{display: 'none'}}><a href="https://www.flaticon.com/free-icons/megaphone" title="megaphone icons">Megaphone icons created by BomSymbols - Flaticon</a></span><img src={performanceImg} alt="webDev"></img>2+ years Content & Marketing</li>
              <li><span style={{display: 'none'}}><a href="https://www.flaticon.com/free-icons/start-button" title="start button icons">Start button icons created by Freepik - Flaticon</a></span><img src={startup} alt="webDev"></img>Launching 2 startups</li>
            </ul>
          </div> 

        <div className="aboutMeTop" style={{textAlign: 'center'}}>
          <div className="aboutMeTopLeft">
            <div className="AboutMeTopLeftDesc">
              <p>Explaining my development expertese, I work generaly as a Reactjs developer, I handle front-end with Reactjs and CSS (I insiste on staying old school than using Sass and Less), and I handle the back-end part with express and nodejs.<br />
              In server side I prefer using MangoDB or Firebase, but Im also experienced using SQL.</p>
            </div>
            <div className="AboutMeTopLeftProgress">
              <ul>
                <li>
                  <p> - Reactjs, Javascript</p>
                  <div className="outerbar"><div className="innerbar" style={{width: '80%'}} /></div>
                </li>
                <li>
                  <p> - Express, Node.js, APIs</p>
                  <div className="outerbar"><div className="innerbar" style={{width: '60%'}} /></div>
                </li>
                <li>
                  <p> - HTML, CSS, Figma & UI styling</p>
                  <div className="outerbar"><div className="innerbar" style={{width: '80%'}} /></div>
                </li>
              </ul>
            </div>
          </div>

          <div className="aboutMeTopLeft reverseAboutMe">
            <div className="AboutMeTopLeftProgress">
              <ul>
                <li>
                  <p> - Retail, Merchandising</p>
                  <div className="outerbar"><div className="innerbar" style={{width: '65%'}} /></div>
                </li>
                <li>
                  <p> - E-commerce</p>
                  <div className="outerbar"><div className="innerbar" style={{width: '70%'}} /></div>
                </li>
                <li>
                  <p> - Project management, Agile</p>
                  <div className="outerbar"><div className="innerbar" style={{width: '80%'}} /></div>
                </li>
              </ul>
            </div>
            <div className="AboutMeTopLeftDesc">
              <p>I'm experienced in online retail and merchandising, not only I worked as international buyer, but also I was involved in launching a market leader e-commerce website. <br />
              My experience is mainly in turning raw data into retail infromation, so the data can lead the business in right way.</p>
            </div>
          </div>

        </div>
        
      </section>
        
      <section className="mySkills">

        <div className="mySkillsTitle">
          <div className="titleLine"></div>
            <h2 style={{margin: 'auto'}}>My Skills:</h2><br />
          <div className="titleLine"></div>
        </div>

        <div className="skillContent">
          <div className="skillsEducation">
            <h3>My Education</h3>
            <div className="skillBox">
              <div className="skillTitle"><h6>Computer Engineering</h6></div>
              <div className="skillDuration"><p>2007 - 2011</p></div>
              <div className="skillDesc"><p>Tehran, Iran</p></div>
            </div>
            <div className="skillBox">
              <div className="skillTitle"><h6>Intensive-MBA</h6></div>
              <div className="skillDuration"><p>2014</p></div>
              <div className="skillDesc"><p>Tehran University, Iran</p></div>
            </div>
          </div>
          <div className="skillsExperience">
            <h3>My Experiences</h3>
            <div className="skillBox">
              <div className="skillTitle"><h6>Web Developer</h6></div>
              <div className="skillDuration"><p>2022 - current</p></div>
              <div className="skillDesc"><p>Points.com Toronto, Canada <br /> www.points.com</p></div>
            </div>
            <div className="skillBox">
              <div className="skillTitle"><h6>Front-end developer</h6></div>
              <div className="skillDuration"><p>2019 - 2020</p></div>
              <div className="skillDesc"><p>Private company</p></div>
            </div>
            <div className="skillBox">
              <div className="skillTitle"><h6>International Retail Buyer - Merchandiser</h6></div>
              <div className="skillDuration"><p>2016 - 2019</p></div>
              <div className="skillDesc"><p>Iran's market leader e-commerce.<br />www.digikala.com</p></div>
            </div>
          </div>
        </div>

      </section>



      <section className="myWorks" ref={myWorks}>

        <div className="myWorksTitle">
          <div className="titleLine"></div>
            <h2 style={{margin: 'auto'}}>My Designs:</h2><br />
          <div className="titleLine"></div>
        </div>

        <div className="workContent">

          <div className="myComponents">

              <Link to="/components/c1001" style={{textDecoration: 'none', margin: 5}}>
                  <div className="componentBox" onClick={openComponentHandler}>
                      <div className="componentImg" style={{backgroundImage: `URL(${Projectc1001Desktop})`}} />
                      <div className="componentTitle"><h6>Podcast Main Page</h6></div>
                      <div className="componentDesc"><p>HTML, CSS, Reactjs, Firebase</p></div>
                  </div>
              </Link>

              <Link to="/components/c1002" style={{textDecoration: 'none', margin: 5}}>
                  <div className="componentBox" onClick={openComponentHandler}>
                      <div className="componentImg" style={{backgroundImage: `URL(${Projectc1002Desktop})`}} />
                      <div className="componentTitle"><h6>TaskManager Dashboard</h6></div>
                      <div className="componentDesc"><p>HTML, CSS, Reactjs, API</p></div>
                  </div>
              </Link>

              <div className="componentBox" style={{padding: '20px', width: "fit-content", margin: 'auto 40px'}}>
                <p>I'll add others here...</p>
              </div>
          </div>
          

        </div>

      </section>



      <section ref={contactMe}>
      <div className='sectionTitle'>
        <div className="titleLine"></div>
        <h1>Contact Me</h1>
        <div className="titleLine"></div>
      </div>

      <ContactForm />


      <footer>
        <p>&copy; 2023 Jenna's Portfolio. All rights reserved.</p>
      </footer>
        
      </section>
    </container>
    </div>
  );
};

export default PortfolioPage;





