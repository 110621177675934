import React from 'react';
import './miniPlayer.css';
import track from '../assets/Tranck-n2.mp3'

const MiniPlayer = (props) => {

    const podcastName = props.title.length > 25 ? `${props.title.slice(0,25)}...` : props.title;
    const backgroundColor = props.genre === "economy" ? '#9D29F1' : 
        props.genre === "documentry" ? '#F7A253' : 
        props.genre === "business" ? '#A529EB' : 
        props.genre === "mystery" ? '#FFB706' : 
        '#F7A253';

    return ( 
        <div className="miniPlayer" style={{backgroundColor: backgroundColor}}>
            <div className="miniPlayerImage">
                <p>{podcastName.slice(0,1)}</p>
            </div>
            <div>
                <h6 style={{color: 'black'}}>{props.podcaster}</h6>
                <h3 style={{color: 'black', fontWeight: '700'}}>{podcastName}</h3>
                <audio controls className="miniAudio" >
                    <source src={track} type="audio/mpeg" />
                </audio>
            </div>
        </div>
     );
}
 
export default MiniPlayer;