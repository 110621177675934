import React, {useEffect} from 'react';
import './podcastProjectOne.css'
import MusicPlayer from './elements/musicPlayer';
import MiniPlayer from './elements/miniPlayer';
import Podcaster from './elements/podcaster'

import mic from './assets/images/mic.png'
import podcast from './assets/images/podcast.png'
import microphone from './assets/images/microphone.png'
import spotify from './assets/images/spotify.png'



const PodcastProjectOne = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])


    const podcasters = [
        {name: 'Economist', genre: 'economy', podcaster: 'Jake Doe', desc: 'Photo by <a href="https://unsplash.com/@cesarfrv93?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">César Rincón</a> on <a href="https://unsplash.com/s/photos/face?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTd8fGZhY2V8ZW58MHx8MHx8fDI%3D&auto=format&fit=crop&w=800&q=60', follower:'40'},
        {name: 'Brain freeze', genre: 'mystery', podcaster: 'Barbara Doe', desc: 'Photo by <a href="https://unsplash.com/it/@aiony?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Aiony Haust</a> on <a href="https://unsplash.com/s/photos/face?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fGZhY2V8ZW58MHx8MHx8fDI%3D&auto=format&fit=crop&w=800&q=60', follower:'23'},
        {name: 'ChannelB', genre: 'documentry', podcaster: 'Ali Bandari', desc: 'Photo by <a href="https://unsplash.com/@midashofstra?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Midas Hofstra</a> on <a href="https://unsplash.com/s/photos/young-man?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fHlvdW5nJTIwbWFufGVufDB8fDB8fHwy&auto=format&fit=crop&w=800&q=60', follower:'18'},
        {name: 'Bir Kitab Gibi', genre: 'documentry', podcaster: 'Timuchin J', desc: 'Photo by <a href="https://unsplash.com/es/@christianbuehner?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">christian buehner</a> on <a href="https://unsplash.com/s/photos/face?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1624224971170-2f84fed5eb5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzV8fGZhY2V8ZW58MHx8MHx8fDI%3D&auto=format&fit=crop&w=800&q=60', follower:'35'},
        {name: 'Freelancers', genre: 'business', podcaster: 'Jon Doe - Jane Doe', desc: 'Photo by <a href="https://unsplash.com/es/@sevcovic23?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Petr Sevcovic</a> on <a href="https://unsplash.com/s/photos/young-man?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1520341280432-4749d4d7bcf9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fHlvdW5nJTIwbWFufGVufDB8fDB8fHwy&auto=format&fit=crop&w=800&q=60', follower:'66'},
        {name: 'Virginia Lake stories', genre: 'mystery', podcaster: 'Charlotte Kent', desc: 'Photo by <a href="https://unsplash.com/@weiding22?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Wei Ding</a> on <a href="https://unsplash.com/s/photos/face?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1560918801-53fe5c710a80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fGZhY2V8ZW58MHx8MHx8fDI%3D&auto=format&fit=crop&w=800&q=60', follower:'34'},
        {name: 'Never enough!', genre: 'economy', podcaster: 'Dr.Renata Redington', desc: 'Photo by <a href="https://unsplash.com/@jakenackos?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jake Nackos</a> on <a href="https://unsplash.com/s/photos/face?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZmFjZXxlbnwwfHwwfHx8Mg%3D%3D&auto=format&fit=crop&w=800&q=60', follower:'22'},
        {name: 'Bplus', genre: 'documentry', podcaster: 'Ali Bandari', desc: 'Photo by <a href="https://unsplash.com/@charliegreen998?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Charlie Green</a> on <a href="https://unsplash.com/s/photos/man?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1615109398623-88346a601842?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fG1hbnxlbnwwfHwwfHx8Mg%3D%3D&auto=format&fit=crop&w=800&q=60', follower:'16'},
        {name: 'Backstage photos', genre: 'documentry', podcaster: 'Charley Ch.', desc: 'Photo by <a href="https://unsplash.com/@sadswim?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">ian dooley</a> on <a href="https://unsplash.com/s/photos/face?license=free&utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>', img: 'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTR8fGZhY2V8ZW58MHx8MHx8fDI%3D&auto=format&fit=crop&w=800&q=60', follower:'12'},
    ]
    const podcasts = [
        {id: 1, name: "Who got my money?", podcaster: "Economist", genre: 'economy'},
        {id: 2, name: "Why we sleep?", podcaster: "Bplus", genre: 'documentry'},
        {id: 3, name: "Get up, no time to waste.", podcaster: "Freelancers", genre: 'business'},
        {id: 4, name: "Biggest crime in history.", podcaster: "ChannelB", genre: 'documentry'},
        {id: 5, name: "Ne oluyoruz? Ne anliyoruz?", podcaster: "Bir Kitab Gibi", genre: 'documentry'},
        {id: 6, name: "Think again, we could live better.", podcaster: "Bplus", genre: 'documentry'},
        {id: 7, name: "Steven Summit, Story no.1", podcaster: "Virginia Lake stories", genre: 'mystery'},
        {id: 8, name: "Dollar dollar, capitalism.", podcaster: "Never enough!", genre: 'economy'},
        {id: 9, name: "Jennifer Kalia, rising star", podcaster: "Backstage photos", genre: 'documentry'},
        {id: 10, name: "A house, but no doors.", podcaster: "Brain freeze", genre: 'mystery'},
    ]

    return ( 
        <div className={'podcastProjectOnePage'}>


            <div className="c1001Header">
                <div className="c1001Logo">Podmaster</div>
                <div className="c1001Navlinks">
                    <ul>
                        <li>Eposide</li>
                        <li>Platform</li>
                        <li>Price</li>
                    </ul>
                </div>
                <div className="c1001Login">
                    <ul>
                        <li>Login</li>
                        <li><button>Join us</button></li>
                    </ul>
                </div>
            </div>


            <div className="c1001Body">
                <div className="c1001Banner">
                    <div className="c1001BannerLeft">
                        <h1>Immerse your senses, elevate your mind<br />Tune in to captivating podcasts.</h1>
                        <p>Explore latest episodes from top rated podcasters, now in 16 different categories!</p>
                        <button className="c1001BannerBtn">Start Listening</button>

                        <div className="c1001MusicPlayer">
                            <p> - New Episode from PodKing! -<br />All about Drake Benjamin, the killer &gt;:)</p>
                            <MusicPlayer />
                        </div>
                    </div>
                    <div className="c1001BannerRight"></div>
                </div>
                <div className="c1001SectionTitle">
                    <h3>Most Populars</h3>
                    <p>See All</p>
                </div>
                <div className="c1001WeeklyPopular" style={{backgroundColor: 'white'}}>
                    
                    {podcasts.slice(0,3).map(pod => <MiniPlayer podcaster={pod.podcaster} title={pod.name} genre={pod.genre} />)}
                </div>
                <div className="c1001SelfAd">
                    <div className="c1001AdLeft">
                        <img src={mic} alt="mic" />
                    </div>
                    <div className="c1001AdRight">
                        <h1 style={{color: 'black'}}>Wy Podmaster?</h1>
                        <p style={{color: 'black'}}>I got this sticker from flaticon, so for support, I put the link here:<br /><a href="https://www.flaticon.com/free-stickers/microphone" title="microphone stickers">Microphone stickers created by smashingstocks - Flaticon</a>
                        <br />Also, for the banner I used my favorite website, Unsplash! I put the link below: <br />Photo by <a href="https://unsplash.com/@mrthetrain?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Joshua Hoehne</a> on <a href="https://unsplash.com/photos/6M9jjeZjscE?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                        <br />Beside all links and taggings, here will be a place to write WHAT VALUES are this podcast adding to people.<br /> so basically your brand's main message.<br />
                        So don't hesitate to shout out whatever you have to offer, brief and bold!</p>
                    </div>
                </div>
                <div className="c1001Latest">
                    <div className="c1001LatestBlackBox">
                        <div className="c1001LatestImage">
                            <div/>
                        </div>
                        <div className="c1001LatestDescription">
                            <h6>Episode 06</h6>
                            <h1>Jennifer Kalia, rising star</h1>
                            <p>Hear Jennifer's life story from her, maybe this will be the last time you judge anyone ...</p>
                            <p>ImageLink: Photo by <a href="https://unsplash.com/ko/@anamnesis33?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Andrey K</a> on <a href="https://unsplash.com/t/people?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="c1001SectionTitle">
                    <h3>Explore New Episodes</h3>
                    <p>See All</p>
                </div>
                <div className="c1001SelfAdSecond">
                    <div className="c1001SearchOptions">
                        <ul>
                            <li style={{color: 'black'}}>Documentry</li>
                            <li style={{color: 'black'}}>Economy</li>
                            <li style={{color: 'black'}}>Mystery</li>
                            <li style={{color: 'black'}}>Business</li>
                        </ul>
                    </div>
                    <div className="c1001ExploreLatest">
                        {podcasts.slice(3,9).map(pod => <MiniPlayer podcaster={pod.podcaster} title={pod.name} genre={pod.genre} />)}
                    </div>
                </div>
                <div className="c1001SelfAdThird">
                    <h4 style={{color: 'black'}}>Explore New Episodes</h4>
                    <p style={{color: 'black'}}>Notify Anywhere you want</p>
                    <h1 style={{color: 'black'}}>Listen 24/7, ANYWHERE!</h1><br />
                    <p style={{fontSize: '0.7rem', lineHeight: '0.6rem', margin: 2}}><a style={{color: 'gray'}} href="https://www.flaticon.com/free-icons/podcast" title="podcast icons">Podcast icons created by Freepik - Flaticon</a></p>
                    <p style={{fontSize: '0.7rem', lineHeight: '0.6rem', margin: 2}}><a style={{color: 'gray'}} href="https://www.flaticon.com/free-icons/podcast" title="podcast icons">Podcast icons created by judanna - Flaticon</a></p>
                    <p style={{fontSize: '0.7rem', lineHeight: '0.6rem', margin: 2}}><a style={{color: 'gray'}} href="https://www.flaticon.com/free-icons/spotify" title="spotify icons">Spotify icons created by Freepik - Flaticon</a></p>
                    <div className="c1001PodcastIcons">
                        <ul>
                            <li><img width="45" src={podcast} alt="podcast" /></li>
                            <li><img width="45" src={microphone} alt="app" /></li>
                            <li><img width="45" src={spotify} alt="spotify" /></li>
                        </ul>
                    </div>
                </div>
                <div className="c1001SectionTitle">
                    <h3>Top Podcasters</h3>
                    <p>See All</p>
                </div>
                <div className="c1001TopPodcasters">
                    {podcasters.slice(0,6).map(item => <Podcaster name={item.podcaster} img={item.img} genre={item.genre} desc={item.desc} follower={item.follower} />)}
                </div>
                <div className="c1001SectionTitle">
                    <h3>Flexible Plans</h3>
                    <p>See All</p>
                </div>
                <div className="c1001Subscription">
                    <p style={{color: 'black'}}>Select what suits your ear, let us take care<br /> of your pocket!</p>
                    <div className="c1001PlanSelect">
                        <p style={{color: 'black'}}>Monthly</p>
                        <p style={{color: 'black'}}>Yearly</p>
                    </div>
                    <div className="c1001PlanBox">
                        <div className="c1001Plan c1001Monthly">
                            <h3 style={{color: 'black'}}>Basic Plan</h3>
                            <p style={{color: 'black'}}><span style={{fontSize: '2.5rem', fontWeight: '800'}}>$35</span>/year</p>
                            <p style={{fontSize: '0.8rem', color: 'black'}}>Explore great stories from</p>
                            <ul>
                                <li style={{color: 'black'}}>Download up to 30 podcasts</li>
                                <li style={{color: 'black'}}>Add two devices</li>
                                <li style={{color: 'black'}}>Whitout disturbing ads</li>
                                <li style={{color: 'black'}}>High quality audio</li>
                                <li style={{color: 'black'}}>Get the latest topic</li>
                                <li style={{color: 'black'}}>Unlimited skip audio</li>
                            </ul>
                            <button style={{color: 'black'}} className="c1001PlanSubscrption">Subscribe</button>
                        </div>
                        <div className="c1001Plan c1001Yearly">
                            <h3 style={{color: 'black'}}>Premium Plan</h3>
                            <p style={{color: 'black'}}><span style={{fontSize: '2.5rem', fontWeight: '800'}}>$65</span>/year</p>
                            <p style={{fontSize: '0.8rem', color: 'black'}}>Unlimited access to podcasts</p>
                            <ul>
                                <li style={{color: 'black'}}>Download unlimited podcasts</li>
                                <li style={{color: 'black'}}>Add 4 devices</li>
                                <li style={{color: 'black'}}>Whitout disturbing ads</li>
                                <li style={{color: 'black'}}>Spatial quality audio</li>
                                <li style={{color: 'black'}}>Get the latest topic</li>
                                <li style={{color: 'black'}}>Unlimited skip audio</li>
                            </ul>
                            <button className="c1001PlanSubscrption">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="c1001Footer">
                <p>Created for my personal portfolio.</p>
            </div>
        </div>
     );
}
 
export default PodcastProjectOne;