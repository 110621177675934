import React from 'react';
import { Link } from 'react-router-dom';

import '../../portfolioPage/portfolioPage.css'
import './navbar.css'


const Navbar = (props) => {

    return ( 
        <header className="navbar">
            <Link to="/" style={{textDecoration: 'none'}}><h1 style={{fontWeight: "100"}}>Jenna's Portfolio</h1></Link>

            <div className="theme-toggle">
            <label className="switch">
                <input 
                    type="checkbox" 
                    onChange={() => props.onThemeChange()} 
                    value={props.value}
                    checked={props.value === 'dark'} />
                <span className="slider round"></span>
            </label>
            </div>

        </header>
    );
}
 
export default Navbar;