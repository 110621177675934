import React, {useState, useEffect} from 'react';
// import axios from "axios";
import './saveEarth.css'

import novel from './assets/images/romantic-novel_5195923.png';
import candle from './assets/images/candle_2146319.png';
import cloudy from './assets/images/cloudy_1146869.png';
import partlySunny from './assets/images/sun_9361629.png';
import fullSun from './assets/images/sun_2426634.png';
import todoIcon from './assets/images/school-material_3371713.png'
import remember from './assets/images/think-green_6752410.png';
import calendarLeaf from './assets/images/rosemary_4711549.png';

const SaveEarth = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])


    //CALENDAR  
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const CurrentDate = new Date();
    const monthName = monthNames[CurrentDate.getMonth()];
    const CurrentYear = new Date().getFullYear();

    const [ calendarView, setCalendarView ] = useState(true)

    
    let fullYearCalendar = {};
    // initialize date to Jan 1, 1900
    let date = new Date(0, 0, 1);
    // day length in milliseconds
    let dayLength = 1000 * 60 * 60 * 24;
    // year length in days (account for leap years)
    let year = date.getFullYear();
    let yearLength = ((year % 4) || (!(year % 100) && (year % 400))) ? 365 : 366;
    for (let i = 0; i < yearLength; i ++) {
    // determine month
    let month = date.toLocaleDateString('en-US', {month: 'long'});
    // determine weekday
    let weekday = date.toLocaleDateString('en-US', {weekday: 'short'});
    // initialize month if it does not exist
    if (!fullYearCalendar[month])
        fullYearCalendar[month] = [];
    // add current day to month
    fullYearCalendar[month].push({
        date: date.getDate(),
        day: weekday.toLowerCase()
    })
    // increment date by one day
    date = new Date(date.getTime() + dayLength);
    }
    // console.log(fullYearCalendar);

    const weekdays = ['sun','mon','tue','wed','thu','fri','sat']
    const firstDay = fullYearCalendar[monthName][0].day;
    let distanceOfFirstDay = []
    for(let i = 0; i < weekdays.indexOf(firstDay); i++) { 
        distanceOfFirstDay.push("1")
    };


    const calendarEvents = [
        {year: 2023, month: 'July', day: 27, event: 'My Moms birthday'},
        {year: 2023, month: 'July', day: 1, event: 'Graduation day'},
        {year: 2023, month: 'Decembre', day: 2, event: 'My Dads birthday'},
        {year: 2023, month: 'Octobre', day: 2, event: "Jake's wedding"},
        {year: 2023, month: 'January', day: 12, event: 'Aunt Audry is comming to town.'},
        {year: 2023, month: 'June', day: 17, event: 'No Bra day!'},
        {year: 2023, month: 'Septembre', day: 3, event: 'Better Earth, Better Health day.'},
        {year: 2023, month: 'Novembre', day: 2, event: 'My Birthday!'},
        {year: 2023, month: 'July', day: 16, event: "No Laziness, no Chocolate :("},
        {year: 2023, month: 'April', day: 27, event: 'First date with my love, 2 years ago, same day!'}
    ]


    const SEOpenEventHandler = (e) => {
        setCalendarView(prev => !prev)
    }


    // const WEATHER_API = "1627b4a8c5b0677a2554e6e982ec46ec";
    // const [weather, setWeather] = useState({});
    
    // const hook = () => {
    //     axios
    //     .get(
    //         `http://api.weatherstack.com/current?access_key=${WEATHER_API}&query=Toronto`
    //     )
    //     .then((response) => {
    //         console.log("then", response);
    //         setWeather({
    //         temperature: response.data.current.temperature,
    //         img: response.data.current.weather_icons,
    //         wind: response.data.current.wind_speed,
    //         dir: response.data.current.wind_dir
    //         });
    //         console.log(JSON.stringify(weather));
    //     });
    // };

    // useEffect(hook, []);

    // console.log(weather);

    

    //todo part
    const [seTodoItems, setSeTodoItems] = useState([
        {id:0, type: 'todo', title: "Learn Spanish, 10 new words for today", complete: false},
        {id:1, type: 'todo', title: "Write a Letter to Your Future Self", complete: false},
        {id:2, type: 'todo', title: "Read 30pages: Wager by David Grann", complete: true},
        {id:3, type: 'todo', title: "50 push-ups at 8:00am", complete: false},
        {id:4, type: 'remember', title: "Call Mary for her birthday", complete: true},
        {id:5, type: 'remember', title: "Call Mom <3", complete: false},
        {id:6, type: 'remember', title: "You're Awesome!", complete: true},
        {id:7, type: 'remember', title: "Think about what you have today :)", complete: false},
    ])

    const SETodoCheckHandler = (e) => { 
        let SEtempTodo = seTodoItems;
        let SETempTodoItem = SEtempTodo.filter(el => el.id === e)
        let SETempTodoIndex = SEtempTodo.indexOf(...SETempTodoItem)

        SEtempTodo[SETempTodoIndex].complete === false ? SEtempTodo[SETempTodoIndex].complete = true : SEtempTodo[SETempTodoIndex].complete = false;
        setSeTodoItems(() => [...SEtempTodo])
    }


    //notes part
    const [longText, setLongText] = useState(false)
    const SELongTextHandler = () => {
        setLongText(prev => !prev)
    }


    return ( 
 
            <div className="SaveEarth">
                <div className="saveEarth__header">
                    <div className="saveEarth__header_background" style={{backgroundImage:` URL("https://images.unsplash.com/photo-1466781783364-36c955e42a7f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQ2fHxsaWZlfGVufDB8MHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60")`}}>
                        <div className="saveEarth__header_icons">
                            <ul>
                                <li><img src={candle} style={{width: '6rem'}} alt="Freepik"/><span style={{display: 'none'}}><a href="https://www.freepik.com/search?format=search&last_filter=query&last_value=candle&query=candle&sort=relevance&type=icon">Icon by justicon</a></span></li>
                                <li><button className="saveEarth__header_button_hover">Change cover</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="saveEarth__intro">
                    <h1>HOME</h1>
                    <p style={{fontSize: '1rem', lineHeight: '1.5rem'}}><img src={novel} style={{height: '1.8rem',transform: 'translateY(0.5rem)', margin: '0 5px 0 6px'}} alt="FreePik" />Personal portfolio, Add comment</p>
                    <div className="saveEarth__intro_motivation_phrase">
                        <p>“The two most important requirements for major success are: first, being in the right place at the right time, and second, doing something about it.” - Ray Kroc</p>
                    </div>
                    
                </div>
                <div className="saveEarth__intro_coloredBox" />
                <div className="saveEarth__visualDashboard">
                    <div className="saveEarth__visualDashboard_calendar">
                        <div className="saveEarth__visualDashboard_calendar_box">
                            <div className="saveEarth__visualDashboard_calendar_title">
                                <h3>{monthName}</h3>
                                <h3>{CurrentYear}</h3>
                            </div>
                            <div className="saveEarth__visualDashboard_week_days" style={{display: calendarView ? 'flex' : 'none'}}>
                                <ul>
                                    <li>Sun</li>
                                    <li>Mon</li>
                                    <li>Tue</li>
                                    <li>Wed</li>
                                    <li>Thu</li>
                                    <li>Fri</li>
                                    <li>Sat</li>
                                </ul>
                            </div>
                            <div className="saveEarth__visualDashboard_calendar_days" style={{display: calendarView ? 'flex' : 'none'}}>
                                {distanceOfFirstDay.map((gap, index) => <div key={index} className="saveEarth__visualDashboard_calendar_empty_box" />)}
                                {fullYearCalendar[monthName].map((el, index) => <div className="saveEarth__visualDashboard_calendar_day_box" key={index} 
                                    style={{backgroundColor: new Date().getFullYear() === CurrentYear && monthNames[CurrentDate.getMonth()] === monthName && el.date === new Date().getDate() ? "#FFFFFF": null}}
                                    onClick={calendarEvents.filter(item => item.year ===CurrentYear).filter(item => item.month === monthName).filter(item => item.day === el.date).length > 0 ? (() => SEOpenEventHandler(el)) : null}>
                                    <p>{el.date}</p>
                                    {calendarEvents.filter(item => item.year ===CurrentYear).filter(item => item.month === monthName).filter(item => item.day === el.date).map((index) => <img src={calendarLeaf} alt="event" style={{width: 30, transform: 'rotate(-16deg)'}} />) }
                                </div>)}  
                            </div>
                            <div className="saveEarth__visualDashboard_events" style={{display: calendarView ? 'none' : 'flex'}}>
                                <ul>
                                {calendarEvents.filter(item => item.year ===CurrentYear).filter(item => item.month === monthName).map((el) => <li><p>{el.month},{el.day < 10 ? `0${el.day}` : el.day} - <b>{el.event}</b></p></li>) }
                                <button onClick={SEOpenEventHandler}>Close</button>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="saveEarth__visualDashboard_weather_progressBar">
                        <div className="saveEarth__visualDashboard_weather">
                            <div className="saveEarth__visualDashboard_weather__top">
                                <div>
                                    <p><b>Toronto, Downtown</b></p>
                                    <p>Partly Cloudy, 16° C</p>
                                </div>
                                <div>
                                    <img src={cloudy} style={{width: 100}} alt="cloudy" /><span style={{display: 'none'}}><a href="https://www.freepik.com/search?format=search&last_filter=query&last_value=cloudy&query=cloudy&type=icon">Icon by Freepik</a></span>
                                </div>
                            </div>
                            <div className="saveEarth__visualDashboard_weather__button">
                                <ul>
                                    <li><p>Sun</p><img src={cloudy} alt="weather" /><p><b>10° C</b><br />9° C</p></li>
                                    <li><p>Mon</p><img src={partlySunny} alt="weather" /><p><b>14° C</b><br />9° C</p></li>
                                    <li><p>Tue</p><img src={fullSun} alt="weather" /><p><b>17° C</b><br />9° C</p></li>
                                    <li><p>Wedß</p><img src={fullSun} alt="weather" /><p><b>22° C</b><br />9° C</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="saveEarth__visualDashboard_progressBar">
                            <div className="saveEarth__progressBar saveEarth__progressBar__yearly">
                                <div className="saveEarth__progressBar__outerBox">
                                    <div className="saveEarth__progressBar__innerBox" style={{width: '25%'}} />
                                </div>
                                <p>Yearly: 25% progress</p>
                            </div>
                            <div className="saveEarth__progressBar saveEarth__progressBar__monthly">
                                <div className="saveEarth__progressBar__outerBox">
                                    <div className="saveEarth__progressBar__innerBox" style={{width: '40%'}} />
                                </div>
                                <p>Monthly: 40% progress</p>
                            </div>
                            <div className="saveEarth__progressBar saveEarth__progressBar__weekly">
                                <div className="saveEarth__progressBar__outerBox">
                                    <div className="saveEarth__progressBar__innerBox" style={{width: '67%'}} />
                                </div>
                                <p>Weekly: 67% progress</p>
                            </div>
                            <div className="saveEarth__progressBar saveEarth__progressBar__daily">
                                <div className="saveEarth__progressBar__outerBox">
                                    <div className="saveEarth__progressBar__innerBox" style={{width: '85%'}} />
                                </div>
                                <p>Daily: 85% progress</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="saveEarth__todo__empty_box" />


                <div className="saveEarth__todo">
                    <div>
                        <div className="saveEarth__todo__goals">
                            <h1 style={{margin:'20px 0'}}><img style={{width: 27, marginRight: 10}} src={todoIcon} alt="todo" />Goals</h1>
                            <ul>
                                {seTodoItems.filter(item => item.type === "todo").map(el => <li key={el.id}><input type="checkbox" checked={el.complete} onChange={() => SETodoCheckHandler(el.id)} /><p>{el.title}</p></li>)}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="saveEarth__todo__goals">
                            <h1 style={{margin:'20px 0'}}><img style={{width: 27, marginRight: 10}} src={remember} alt="todo" />Things To Remember</h1>
                            <ul>
                                {seTodoItems.filter(item => item.type === "remember").map(el => <li key={el.id}><input type="checkbox" checked={el.complete} onChange={() => SETodoCheckHandler(el.id)} /><p>{el.title}</p></li>)}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="saveEarth__todo__empty_box_blue" />

                <div className="saveEarth__notes">
                    <h1 style={{margin:'20px 0'}}><img style={{width: 27, marginRight: 10}} src={todoIcon} alt="todo" />Notes for today's meeting :)</h1>
                    <p>
                        <b>Intro question</b><br /><br />
                        Start with an open-ended question. How was last week? What’s been working well for you lately? <br /> <br />

                        <b>Celebrate Wins & Lessons Learned </b><br /><br />
                        What have we accomplished since our last meeting? Note progress on important initiatives. <br /> <br />
                        
                        <span style={{display: longText ? 'block' : 'none'}}>

                            <b>How can we be better?</b> Highlight lessons learned from the previous week. <br /> <br />

                            <b>Remove Roadblocks</b> <br /><br />
                            What (if anything) is stopping—or slowing down—your progress? How can we remove that roadblock? What support do you need?  <br /><br />

                            Plan to remove specific roadblocks and create action items. <br /> <br />

                            <b>Two-Way Evaluation and Feedback</b> <br /><br /> 
                            How are we doing? How can we work together more effectively?  <br /> <br />

                            <b>Open Discussion</b> <br /><br /> 
                            Provide time for open discussion. Is there anything else you want to talk about? <br /> <br /> 

                            <b>Follow-Up</b> <br /><br /> 
                            Should we schedule another one-on-one? Review any action items arising from the one-on-one.

                        </span>
                    </p>
                    <br />
                    <button  style={{display: longText ? 'none' : 'block'}} onClick={SELongTextHandler} >See More</button>
                    <button  style={{display: longText ? 'block' : 'none'}} onClick={SELongTextHandler} >See Less</button>
                </div>


                <div className="saveEarth__todo__empty_box_yellow" style={{margin: '40px 0'}} />

                <div className="saveEarth__footer" style={{width: '100%'}}>
                    <p style={{margin: '20px auto 0', width: '100%', textAlign: 'center'}}>Customize your page, as you like</p>
                    <p style={{margin: '20px auto', width: '100%', textAlign: 'center'}}>This page is just a UI sample, to preview in Jenna's portfolio</p>
                </div>
            </div>

     );
}
 
export default SaveEarth;