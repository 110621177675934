import React, { useState } from 'react';
import './contactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [ formSendStatus, setFormSendStatus ] = useState(false)

  const backToFormHandler = () => { 
    setFormSendStatus(false)
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      // Perform form submission logic here

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ date: new Date(), data: formData })
    };
    fetch('https://jennaportfolio2023-default-rtdb.firebaseio.com/messages.json', requestOptions)
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('There was an error!', error);
        });




      console.log('Form submitted:', formData);
    }
    setFormSendStatus(true)
  };

  const handleClear = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      message: '',
    });
    setFormErrors({});
  };

  const validateForm = (data) => {
    const errors = {};

    // Regular expressions for form validation
    const nameRegex = /^[a-zA-Z\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    if (!data.firstName.trim()) {
      errors.firstName = 'First name is required';
    } else if (!nameRegex.test(data.firstName)) {
      errors.firstName = 'Invalid first name';
    }

    if (data.lastName && !nameRegex.test(data.lastName)) {
      errors.lastName = 'Invalid last name';
    }

    if (!data.email.trim()) {
      errors.email = 'Email address is required';
    } else if (!emailRegex.test(data.email)) {
      errors.email = 'Invalid email address';
    }

    if (data.mobile && !mobileRegex.test(data.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }

    if (!data.message.trim()) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  return (
    <div className="contactForm">
      
      <form onSubmit={handleSubmit}>
        <div className="form-group" style={{display: formSendStatus === true ? 'none' : 'block'}}>
        <div className="flexInDesktop">
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className={formErrors.firstName ? 'error' : ''}
          />
          </div>
          {formErrors.firstName && (
            <span className="error-message">{formErrors.firstName}</span>
          )}
        </div>
        <div className="form-group" style={{display: formSendStatus === true ? 'none' : 'block'}}>
            <div className="flexInDesktop">
                <label htmlFor="lastName">Last Name</label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className={formErrors.lastName ? 'error' : ''}
                />
            </div>
          {formErrors.lastName && (
            <span className="error-message">{formErrors.lastName}</span>
          )}
        </div>
        <div className="form-group" style={{display: formSendStatus === true ? 'none' : 'block'}}>
        <div className="flexInDesktop">
          <label htmlFor="email">Email Address *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={formErrors.email ? 'error' : ''}
          />
          </div>
          {formErrors.email && (
            <span className="error-message">{formErrors.email}</span>
          )}
        </div>
        <div className="form-group" style={{display: formSendStatus === true ? 'none' : 'block'}}>
        <div className="flexInDesktop">
          <label htmlFor="mobile">Mobile Number</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className={formErrors.mobile ? 'error' : ''}
          />
          </div>
          {formErrors.mobile && (
            <span className="error-message">{formErrors.mobile}</span>
          )}
        </div>
        <div className="form-group" style={{display: formSendStatus === true ? 'none' : 'block'}}>
        <div className="flexInDesktop">
          <label htmlFor="message">Message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className={formErrors.message ? 'error' : ''}
          />
          </div>
          {formErrors.message && (
            <span className="error-message">{formErrors.message}</span>
          )}
        </div>
        <div className="FormSend" style={{display: formSendStatus === false ? 'none' : 'block'}}>
        <br /><p>Thanks a lot for reaching out to me :)<br /><br /> I will come back to you ASAP ...</p><br />
      </div>
        <div className="form-group form-group-btns">
          <button type="submit" style={{display: formSendStatus === true ? 'none' : 'block'}}>Submit</button>
          <button type="button" style={{display: formSendStatus === true ? 'none' : 'block'}} onClick={handleClear}>Clear</button>
          <button type="button" style={{display: formSendStatus === false ? 'none' : 'block'}} onClick={backToFormHandler}>Back to Form</button>
        </div>
      </form>
      
    </div>
  );
};

export default ContactForm;
