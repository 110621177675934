import React from 'react';
import './podcaster.css'

const Podcaster = (props) => {
    return ( 
        <div className="podcasterBox" >
            <div className="podcasterImage" style={{backgroundImage: `URL(${props.img})`}} />
            <div className="podcasterDesc">
                <h2>{props.name}</h2>
                <h4>{props.genre}</h4>
                <p>{props.follower}K follower</p>
            </div>
        </div>
     );
}
 
export default Podcaster;