import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';


import SmallProjectPage from './smallProjects/smallProjectPage';
import PortfolioPage from './portfolioPage/portfolioPage';
import './App.css';
import Navbar from './elements/navbar/navbar';
import ProjectFullScreen from './smallProjects/projectFullscreen';
import SaveEarth from './smallProjects/collection/saveEarth/saveEarth';



const App = () => {

  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
  };


  return (
    <BrowserRouter>
      <Navbar onThemeChange={() => toggleTheme()} value={theme} />
      <Routes>
          <Route path="/" exact element={<PortfolioPage theme={theme} />} />
          <Route path="/components/:componentId/full-screen" exact element={<ProjectFullScreen  theme={theme} />} />
          <Route path="/components/save-earth" exact element={<SaveEarth theme={theme} />} />
          <Route path="/components/:componentId" exact element={<SmallProjectPage theme={theme} />} />
          <Route path="/*" element={<Navigate replace to='/' />} />
      </Routes>
    </BrowserRouter>
  )
};

export default App;





